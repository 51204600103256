import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Menu, Modal, message } from "antd";
import { getAuth, signOut } from "firebase/auth";
import {
  BankOutlined,
  ShopOutlined,
  TeamOutlined,
  DollarOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  CalendarOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

const MenuComponent: React.FC<any> = ({ user, isAdmin }) => {
  const navigate = useNavigate();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const name = user.displayName;
  const initials = name?.match(/\b(\w)/g).join("") || "";

  const profile = {
    key: "profile",
    label: name,
    icon: (
      <Avatar className="avatar-class" shape="square" size={24}>
        {initials}
      </Avatar>
    ),
  };

  const reports = getItem(
    "Reportes de venta",
    "456",
    <ReconciliationOutlined />,
    [
      getItem(
        "Método de pago",
        "salesReportByPaymentMethod",
        <CreditCardOutlined />
      ),
      getItem("Habitación", "salesReportByRooms", <ShopOutlined />),
      getItem("Tipo de Habitación", "salesReportByRoomTypes", <ShopOutlined />),
      getItem(
        "Cantidad de Tipo de Habitación",
        "reportByRoomTypeQuantity",
        <ShopOutlined />
      ),
    ]
  );

  const items: any = [
    profile,
    getItem("Agenda", "timeline", <CalendarOutlined />),
    getItem("Reservaciones", "reservations", <ContainerOutlined />),
    getItem("Checkins", "checkins", <FileDoneOutlined />),
    getItem("Usuarios", "users", <TeamOutlined />),
    getItem("Empresas", "companies", <BankOutlined />),
    getItem("Habitaciones", "rooms", <ShopOutlined />),
    getItem("Pagos", "payments", <DollarOutlined />),
  ];

  if (isAdmin) {
    items.push(reports);
  }

  items.push(getItem("Logout", "logout", <LogoutOutlined />));

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: any[]
  ): any {
    return {
      key,
      icon,
      children,
      label,
    } as any;
  }

  const onMenuItemClick = (menu: any) => {
    if (menu.key === "logout") {
      onConfirmLogout();
      return;
    }
    navigate(menu.key);
  };

  const onConfirmLogout = () => {
    confirm({
      title: `Logout`,
      centered: true,
      content: `Desea hacer logout?`,
      okText: "Logout",
      okType: "primary",
      cancelText: "No",
      onOk() {
        onLogout();
      },
    });
  };

  const onLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate("/");
    } catch (error: any) {
      message.error("Error signing out:", error);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      {contextHolder}
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{
          height: "100%",
          borderRight: 0,
          justifyContent: "space-between",
        }}
        items={items}
        onClick={onMenuItemClick}
      />
    </div>
  );
};

export default MenuComponent;
