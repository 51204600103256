import { v4 as uuid } from "uuid";
import { getCalendarColumnDate } from "./Components";
import { getDate, isDateToday, getDateStringDatabase } from "@utils/date";

export const roomsColumn = {
  dataIndex: "rooms",
  title: "",
  fixed: "left",
  width: 200,
};

export const getColumns = (data: any) => {
  if (!data) return [];
  const columns = data?.columns?.map((key: any) => {
    const isToday = isDateToday(key);
    const title = getCalendarColumnDate(key);
    return {
      dataIndex: key,
      title,
      width: 120,
      className: isToday ? "today-class" : "cell-class",
      onCell: () => ({ colSpan: 1 }),
    };
  });
  return [roomsColumn, ...columns];
};

export const getDataSource = (
  data: any,
  filter: any,
  draggable: any,
  droppable: any
) => {
  return data?.rooms?.map((room: any) => {
    const cell: any = {};
    assignDraggable(data, room, cell, filter, draggable);

    data?.columns?.forEach((date: string) => {
      if (cell[date]) return;
      cell[date] = droppable(uuid(), { date, room });
    });

    return {
      id: uuid(),
      rooms: `${room.name} ${room.type}`,
      ...cell,
    };
  });
};

export const assignDraggable = (
  data: any,
  room: any,
  cell: any,
  filter: any,
  draggable: any
) => {
  const reservations = data.rows[room.id];
  const [initialColumn] = data.columns;
  const initialColumnDate = getDate(initialColumn);

  reservations?.forEach((reservation: any) => {
    const roomsCount = data.reservationIndexMap[reservation.reservationId];
    const initialDate = getDate(reservation.initialDate);
    const startDate: any = initialDate?.isBefore(initialColumnDate)
      ? initialColumn
      : reservation.initialDate;
    cell[startDate] = draggable(uuid(), {
      reservation,
      roomsCount,
      givenDate: filter.date,
    });
  });
};

export const getTimelineReservation = (active: any, over: any) => {
  const reservation = active?.reservation;

  const totalDates = reservation.dates.length;
  const initialDate = getDate(over.date);
  const finalDate = getDate(over.date)?.add(totalDates - 1, "day");

  return {
    reservationId: reservation.reservationId,
    oldRoomId: reservation.roomId,
    newRoomId: over.room.id,
    newInitialDate: getDateStringDatabase(initialDate),
    newFinalDate: getDateStringDatabase(finalDate),
  };
};
