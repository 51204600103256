import React, { useEffect, useState } from "react";

import { isUserAdmin } from "@utils/user";
import { getRooms } from "@services/roomService";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { getDateDatabase, getDateRange, getTime } from "@utils/date";
import { Tabs, Card, Form, Spin, Space, Button, message } from "antd";
import {
  buttonLayout,
  formLayout,
  cardFormLayout,
} from "@constants/formLayout";

import {
  createReservation,
  updateReservation,
  getRoomsByReservationId,
  getPaymentsByReservationId,
} from "@services/reservationService";

import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

import IFieldData from "@interfaces/IFieldData";
import IReservation from "@interfaces/IReservation";
import RoomDetails from "@components/Reservations/RoomDetails";
import NotesDetails from "@components/Reservations/NotesDetails";
import PaymentDetails from "@components/Reservations/PaymentDetails";
import ReservationDetails from "@components/Reservations/ReservationDetails";

const ReservationForm: React.FC<IFormProps> = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);
  const [roomOptions, setRoomOptions] = useState<any>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const reservation: IReservation = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  useEffect(() => {
    checkIfUserIsAdmin();
  }, []);

  const checkIfUserIsAdmin = async () => {
    const isAdmin = await isUserAdmin();
    setIsAdmin(isAdmin);
  };

  useEffect(() => {
    setLoading(true);
    fetchRoomsData();
    fetchReservationData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation]);

  const fetchRoomsData = async () => {
    try {
      const rooms = await getRooms();
      const roomOptions = rooms?.map((room: any) => ({
        key: room.id,
        label: `${room.name} - ${room.type}`,
        value: room.id,
        price: room.price,
      }));
      setRoomOptions(roomOptions);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    }
  };

  const fetchReservationData = async () => {
    if (reservation?.id) {
      const { id } = reservation;
      reservation.containsCompany = !!reservation.companyId;
      reservation.arrivalTime = getTime(reservation.arrivalTime);
      reservation.rooms = await getRoomsByReservationId(id);
      reservation.payments = await getPaymentsByReservationId(id);
      reservation.dates = [
        getDateDatabase(reservation.initialDate),
        getDateDatabase(reservation.finalDate),
      ];

      setFields(getFieldData(reservation));
    }
  };

  const onFieldsChangeForm = (changedFields: any) => {
    const [field] = changedFields;
    const [name] = field.name;
    const { value } = field;

    if (name === "rooms") {
      onRoomChange(name, field);
    }

    if (name === "dates") {
      onDateChange(value);
    }
  };

  const onRoomChange = (name: string, field: any) => {
    const index = field.name[1];
    const key = field.name[2];

    if (name !== "rooms" || key !== "key") return;

    const room = roomOptions?.find((x: any) => x.key === field.value);
    const roomsField = form.getFieldValue("rooms");
    const roomPrice = !room.price || room.price <= 0 ? 500 : room.price;
    roomsField[index].price = roomPrice;
    form.setFieldValue("rooms", roomsField);
  };

  const onDateChange = (dates: any) => {
    if (!dates) return;
    const paymentsField = form.getFieldValue("payments");
    const dateRange = getDateRange(dates[0], dates[1]);
    if (dateRange?.length > 1) dateRange?.pop();

    const newPaymentsField = dateRange?.map((date: string) => {
      const paymentField = paymentsField?.find(
        (payment: any) => payment.date === date
      );
      if (paymentField) return paymentField;
      return {
        date,
      };
    });
    form.setFieldValue("payments", newPaymentsField);
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (data: IReservation) => {
    try {
      setLoading(true);
      const reservationObject = Object.assign({}, reservation, data);

      if (reservation?.id) {
        await updateReservation(reservationObject);
        message.success("Reservación actualizada!");
      } else {
        await createReservation(reservationObject);
        message.success("Reservación creada!");
      }
      goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const reservationDetails = () => {
    return {
      label: "Reservación",
      card: <ReservationDetails form={form} />,
    };
  };

  const roomDetails = () => {
    return {
      label: "Habitaciones",
      card: <RoomDetails roomOptions={roomOptions} />,
    };
  };

  const notesDetails = () => {
    return {
      label: "Notas",
      card: <NotesDetails />,
    };
  };

  const paymentDetails = () => {
    return {
      label: "Pagos",
      card: <PaymentDetails form={form} isAdmin={isAdmin} />,
    };
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card {...cardFormLayout} bordered={false}>
        <Form
          {...formLayout}
          form={form}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChangeForm}
          initialValues={{ rooms: [] }}
        >
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            items={[
              reservationDetails(),
              roomDetails(),
              paymentDetails(),
              notesDetails(),
            ].map((detail, i) => {
              const id = String(i + 1);
              return {
                key: id,
                forceRender: true,
                label: detail.label,
                children: detail.card,
              };
            })}
          />
          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {reservation?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default ReservationForm;
