import React, { useEffect, useState } from "react";
import { isUserAdmin } from "@utils/user";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import { Table, Layout, Card, message, Tag } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { getPaymentsByDate } from "@services/reservationService";
import { getDateDifference, getDateString, getToday } from "@utils/date";
import { isFilterMatched, sortAlphaNum, sortBooleans } from "@utils/util";

import IPayment from "@interfaces/IPayment";
import IReservation from "@interfaces/IReservation";
import DateToolbar from "@components/Table/DateToolbar";
import ApproveComponent from "@components/Table/ApproveComponent";

const { Header, Content } = Layout;

const PaymentsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [data, setData] = useState<IPayment[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPayment>>({});
  const [filteredDate, setFilteredDate] = useState<any>(getToday());

  useEffect(() => {
    fetchData(filteredDate);
    checkIfUserIsAdmin();
  }, [filteredDate]);

  const checkIfUserIsAdmin = async () => {
    const isAdmin = await isUserAdmin();
    setIsAdmin(isAdmin);
  };

  const fetchData = async (filteredDate: string) => {
    try {
      setLoading(true);
      const data = await getPaymentsByDate(filteredDate);
      setData(data);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange: TableProps<IPayment>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IPayment>);
  };

  const onReload = async () => {
    await fetchData(filteredDate);
  };

  const columns: ColumnsType<IPayment> = [
    {
      key: "initialDate",
      title: "Fecha de llegada",
      dataIndex: "initialDate",
      align: "center",
      onFilter: (value: any, record: any) => isFilterMatched(value, record),
      sorter: (a, b) => getDateDifference(a.initialDate, b.initialDate),
      sortOrder:
        sortedInfo.columnKey === "initialDate" ? sortedInfo.order : null,
      render: (initialDate: string) => getDateString(initialDate),
    },
    {
      key: "finalDate",
      title: "Fecha de salida",
      dataIndex: "finalDate",
      align: "center",
      sorter: (a, b) => getDateDifference(a.finalDate, b.finalDate),
      sortOrder: sortedInfo.columnKey === "finalDate" ? sortedInfo.order : null,
      render: (finalDate: string) => getDateString(finalDate),
    },
    {
      key: "userName",
      title: "Cliente",
      dataIndex: "userName",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.userName, b.userName),
      sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
    },
    {
      width: 100,
      align: "center",
      key: "paymentStatus",
      title: "Estado de Pago",
      dataIndex: "paymentStatus",
      sorter: (a, b) => sortAlphaNum(a.paymentStatus, b.paymentStatus),
      sortOrder:
        sortedInfo.columnKey === "paymentStatus" ? sortedInfo.order : null,
    },
    {
      width: 100,
      align: "center",
      key: "paymentMethod",
      title: "Método de Pago",
      dataIndex: "paymentMethod",
      sorter: (a, b) => sortAlphaNum(a.paymentMethod, b.paymentMethod),
      sortOrder:
        sortedInfo.columnKey === "paymentMethod" ? sortedInfo.order : null,
    },
    {
      key: "approved",
      title: "Aprobado",
      dataIndex: "approved",
      align: "center",
      sorter: (a, b) => sortBooleans(a.approved, b.approved),
      sortOrder: sortedInfo.columnKey === "approved" ? sortedInfo.order : null,
      render: (approved: any) => {
        return approved ? (
          <Tag color="#87d068">Aprobado</Tag>
        ) : (
          <Tag color="#f50">Pendiente</Tag>
        );
      },
    },
  ];

  const actionColumn: any = {
    key: "x",
    dataIndex: "",
    title: "Acción",
    align: "center",
    width: 100,
    render: (payment: IReservation) => (
      <ApproveComponent
        onReload={onReload}
        dataObject={payment}
        setLoading={setLoading}
        filteredDate={filteredDate}
      />
    ),
  };

  if (isAdmin) {
    columns.push(actionColumn);
  }

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <DateToolbar
            yearOnly={false}
            filteredDate={filteredDate}
            setFilteredDate={setFilteredDate}
          />
        </Header>
        <Content>
          <Table
            loading={loading}
            dataSource={data}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
            columns={columns}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default PaymentsTable;
