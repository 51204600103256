import axios from "axios";
import IReservation from "@interfaces/IReservation";

import { Moment } from "moment";
import { getConfigHeaders } from "@constants/axiosConfig";
import { getDate, getDateStringDatabase, getYearString } from "@utils/date";

const API_BASE_URL = "/api/reservation";

export const getReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation:", error);
    throw error;
  }
};

export const getReservations = async (filteredDate: Moment) => {
  try {
    const year = getYearString(filteredDate)
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/all/${year}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservations:", error);
    throw error;
  }
};

export const getRoomsByReservationId = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/room/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation rooms:", error);
    throw error;
  }
};

export const getPaymentsByReservationId = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/payment/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation payments:", error);
    throw error;
  }
};

export const getPaymentsByDate = async (date: string) => {
  try {
    const filteredDate = getDateStringDatabase(getDate(date));
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/payment/all`,
      { date: filteredDate },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching reservations payments:", error);
    throw error;
  }
};

export const createReservation = async (reservationData: IReservation) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      reservationData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating reservation:", error);
    throw error;
  }
};

export const updateReservation = async (reservationData: IReservation) => {
  try {
    const { id } = reservationData;
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      reservationData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error updating reservation:", error);
    throw error;
  }
};

export const deleteReservation = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error deleting reservation:", error);
    throw error;
  }
};

export const approvePayment = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/payment/approve/${id}`,
      {},
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error approving payment:", error);
    throw error;
  }
};
