import type { FormLabelAlign } from "antd/es/form/interface";
import { FormLayout } from "antd/es/form/Form";
import { SizeType } from "antd/es/config-provider/SizeContext";

export const formLayout = {
  colon: false,
  labelWrap: true,
  autoComplete: "off",
  size: "large" as SizeType,
  layout: "horizontal" as FormLayout,
  labelAlign: "left" as FormLabelAlign,
  validateMessages: { required: " " },
  style: { maxWidth: 1000 },
  labelCol: { span: 6 },
  wrapperCol: { span: 22 },
};

export const cardFormLayout = {
  style: { maxWidth: 900 },
}

export const buttonLayout = {
  wrapperCol: { offset: 6 },
};

export const paymentLabelSpan = {
  labelCol: {
    sm: { span: 6 },
  },
};
