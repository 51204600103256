import IUser from "@interfaces/IUser";
import ICompany from "@interfaces/ICompany";
import UserForm from "@pages/Users/UserForm";
import TimePicker from "@components/Common/TimePicker";
import DatePicker from "@components/Common/DatePicker";
import CompanyForm from "@pages/Companies/CompanyForm";

import { useEffect, useState } from "react";
import { getUsers } from "@services/userService";
import { getErrorMessage } from "@utils/errorMessage";
import { getCompanies } from "@services/companyService";
import {
  userItem,
  filterData,
  renderItem,
  IRenderItem,
} from "@components/Form/form";
import {
  Form,
  Spin,
  Modal,
  Space,
  Button,
  Select,
  message,
  Switch,
  InputNumber,
} from "antd";

const { RangePicker } = DatePicker;

export interface IReservationDetailsProps {
  form: any;
}

const optionsRender = (option: any) => (
  <>
    <span>
      <b>{option.data.label}</b>
    </span>
    <br />
    <span>{option.data.phone}</span>
  </>
);

const ReservationDetails: React.FC<IReservationDetailsProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [containsCompany, setContainsCompany] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);

  const [userOptions, setUserOptions] = useState<IRenderItem[]>([]);
  const [companyOptions, setCompanyOptions] = useState<IRenderItem[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchUsersData();
    fetchCompaniesData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    setCompanyField();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.getFieldValue("companyId")]);

  const setCompanyField = () => {
    setContainsCompany(!!props.form.getFieldValue("companyId"));
  };

  const fetchUsersData = async () => {
    try {
      const users = await getUsers();
      const userOptions: IRenderItem[] = users?.map((user: IUser) =>
        userItem(user)
      );
      setUserOptions(userOptions);
      return userOptions;
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return [];
    }
  };

  const fetchCompaniesData = async () => {
    try {
      const companies = await getCompanies();
      const companyOptions: IRenderItem[] = companies?.map(
        (company: ICompany) => {
          return renderItem(company);
        }
      );
      setCompanyOptions(companyOptions);
      return companyOptions;
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return [];
    }
  };

  const onUserReload = async (userId: string = "") => {
    setLoading(true);
    await fetchUsersData();
    props.form.setFieldsValue({ userId: userId });
    setLoading(false);
    onModalClose();
  };

  const onCompanyReload = async (companyId: string = "") => {
    setLoading(true);
    await fetchCompaniesData();
    props.form.setFieldsValue({
      companyId: companyId,
    });
    setContainsCompany(true);
    setLoading(false);
    onModalClose();
  };

  const onModalClose = async () => {
    setUserModalOpen(false);
    setCompanyModalOpen(false);
  };

  return (
    <>
      <Spin className="spinner" spinning={loading}>
        <Form.Item name="id" hidden />
        <Form.Item name="status" hidden />

        <Form.Item name="dates" label="Fechas" rules={[{ required: true }]}>
          <RangePicker format={"ddd DD/MM/YY"} style={{ width: "70%" }} />
        </Form.Item>

        <Form.Item
          name="arrivalTime"
          label="Hora de llegada"
          rules={[{ required: true }]}
        >
          <TimePicker use12Hours format="h:mm a" style={{ width: "70%" }} />
        </Form.Item>

        <Form.Item
          name="guests"
          label="Huespedes"
          rules={[{ required: true }]}
          initialValue={2}
        >
          <InputNumber controls={true} min={1} />
        </Form.Item>

        <Form.Item name="userId" label="Cliente" rules={[{ required: true }]}>
          <Space>
            <Form.Item name="userId" noStyle>
              <Select
                showSearch
                style={{ width: 450 }}
                options={userOptions}
                notFoundContent="Usuario no encontrado"
                filterOption={filterData}
                optionRender={optionsRender}
              ></Select>
            </Form.Item>

            <Button type="link" onClick={() => setUserModalOpen(true)}>
              Crear
            </Button>
          </Space>
        </Form.Item>

        <Form.Item
          name={"containsCompany"}
          label={"Es empresa?"}
          valuePropName="checked"
        >
          <Switch onChange={setContainsCompany} />
        </Form.Item>

        {containsCompany && (
          <Form.Item
            name="companyId"
            label="Empresa"
            rules={[{ required: true }]}
          >
            <Space>
              <Form.Item name="companyId" noStyle>
                <Select
                  showSearch
                  style={{ width: 450 }}
                  options={companyOptions}
                  notFoundContent="Empresa no encontrada"
                  filterOption={filterData}
                ></Select>
              </Form.Item>

              <Button type="link" onClick={() => setCompanyModalOpen(true)}>
                Crear
              </Button>
            </Space>
          </Form.Item>
        )}
      </Spin>

      {userModalOpen && (
        <Modal
          title="Crear Cliente"
          open={userModalOpen}
          centered
          width={600}
          footer={[]}
          onCancel={() => onModalClose()}
        >
          <UserForm
            excludeFields
            onReload={(userId: string) => onUserReload(userId)}
            onCancel={() => onModalClose()}
          />
        </Modal>
      )}

      {companyModalOpen && (
        <Modal
          title="Crear empresa"
          open={companyModalOpen}
          centered
          width={600}
          footer={[]}
          onCancel={() => onModalClose()}
        >
          <CompanyForm
            excludeFields
            dataObject={{}}
            onReload={onCompanyReload}
            onCancel={() => onModalClose()}
          />
        </Modal>
      )}
    </>
  );
};

export default ReservationDetails;
