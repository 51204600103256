import axios from "axios";
import IUser from "@interfaces/IUser";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/user";

export const getUser = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/all`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const createUser = async (userData: IUser) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      userData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const updateUser = async (userData: IUser) => {
  try {
    const { id } = userData;
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      userData,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
