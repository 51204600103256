import IFieldData from "@interfaces/IFieldData";
import { message } from "antd";

export interface IFormProps {
  dataObject?: any;
  onReload?: Function;
  onCancel?: Function;
  excludeFields?: boolean;
  includeRequiredFields?: boolean
}

export interface IRenderItem {
  key: string;
  label: string;
  value: string;
}

export const onFinishFailed = () => {
  message.error("Llenar todos los campos requeridos!");
};

export const getFieldData = (fieldData: any): IFieldData[] =>
  Object.entries(fieldData)?.map(([key, value]: any) => ({
    name: key,
    value: value?.key ? value.key : value,
  }));

export const filterData = (inputValue: any, option: any) =>
  option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

export const renderItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
});

export const userItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
  phone: `${value.phonePrefix} ${value.phone}`,
});
