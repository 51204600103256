import { Form, Switch, Radio, Collapse } from "antd";
import { paymentMethod } from "@constants/paymentMethod";
import { paymentStatus } from "@constants/paymentStatus";
import { paymentLabelSpan } from "@constants/formLayout";

const PaymentDetails: React.FC<any> = ({ form, isAdmin }: any) => {
  const getPaymentStatusField = (name: any) => (
    <Form.Item
      {...paymentLabelSpan}
      name={[name, "status"]}
      label="Estado de pago"
      initialValue={paymentStatus.PENDING}
    >
      <Radio.Group size="middle" buttonStyle="solid">
        <Radio.Button value={paymentStatus.PENDING}>
          {paymentStatus.PENDING}
        </Radio.Button>
        <Radio.Button value={paymentStatus.PAID}>
          {paymentStatus.PAID}
        </Radio.Button>
        <Radio.Button value={paymentStatus.CREDIT}>
          {paymentStatus.CREDIT}
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );

  const getPaymentMethodField = (name: any) => (
    <Form.Item
      {...paymentLabelSpan}
      name={[name, "method"]}
      label="Método de pago"
    >
      <Radio.Group size="middle" buttonStyle="solid">
        <Radio.Button value={paymentMethod.CASH}>
          {paymentMethod.CASH}
        </Radio.Button>
        <Radio.Button value={paymentMethod.TRANSFER}>
          {paymentMethod.TRANSFER}
        </Radio.Button>
        <Radio.Button value={paymentMethod.CREDIT_CARD}>
          {paymentMethod.CREDIT_CARD}
        </Radio.Button>
        <Radio.Button value={paymentMethod.CREDIT}>
          {paymentMethod.CREDIT}
        </Radio.Button>
        <Radio.Button value={paymentMethod.PAYMENT_LINK}>
          {paymentMethod.PAYMENT_LINK}
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );

  const getApproverField = (name: any) => (
    <Form.Item
      {...paymentLabelSpan}
      style={{ marginBottom: 0 }}
      name={[name, "approved"]}
      label={"Aprobado"}
      valuePropName="checked"
    >
      <Switch disabled={!isAdmin} />
    </Form.Item>
  );

  const getItems = (fields: any) => {
    const payments = form.getFieldValue("payments");
    return fields.map(({ key, name }: any) => {
      const date = payments[name]?.date
      return {
        key,
        label: (
          <span>
            <b>{date}</b>
          </span>
        ),
        children: (
          <Form.Item key={key} style={{ marginBottom: 0 }}>
            {getPaymentStatusField(name)}
            {getPaymentMethodField(name)}
            {getApproverField(name)}
          </Form.Item>
        ),
      };
    });
  };

  return (
    <>
      <Form.List name="payments">
        {(fields) => (
          <>
            <Collapse
              size="middle"
              collapsible="header"
              items={getItems(fields)}
            />
            <br />
          </>
        )}
      </Form.List>
    </>
  );
};

export default PaymentDetails;
