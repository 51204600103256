import React, { useEffect, useState } from "react";
import { isUserAdmin } from "@utils/user";
import { Content } from "antd/es/layout/layout";
import { FilterFilled } from "@ant-design/icons";
import { getErrorMessage } from "@utils/errorMessage";
import { Droppable } from "@components/Common/Droppable";
import { DndContext, pointerWithin } from "@dnd-kit/core";
import { draggable } from "@components/Timeline/Components";
import { getDate, getDateStringDatabase, getToday } from "@utils/date";
import { Layout, Modal, Table, Drawer, message, FloatButton } from "antd";
import {
  getTimeline,
  updateTimelineReservation,
} from "@services/timelineService";
import {
  getColumns,
  getDataSource,
  getTimelineReservation,
} from "@components/Timeline/timeline";

import moment from "moment";
import es from "moment/locale/es";
import ITimeline from "@interfaces/ITimeline";
import scrollIntoView from "scroll-into-view";
import TimelineModal from "@components/Timeline/Modal";
import TimelineToolbar from "@components/Timeline/Toolbar";

moment.locale("es", es);

const Timeline: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [data, setData] = useState<ITimeline>();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [dragObject, setDragObject] = useState<any>(null);
  const [filter, setFilter] = useState<any>({
    date: getToday(),
  });

  const [selectedReservation, setSelectedReservation] = useState<any>(null);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    scrollIntoView(document.querySelector(".row-class"));
  }, [data]);

  useEffect(() => {
    checkIfUserIsAdmin();
  }, []);

  const checkIfUserIsAdmin = async () => {
    const isAdmin = await isUserAdmin();
    setIsAdmin(isAdmin);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const timelineData = await getTimeline(filter);
      setData(timelineData);
      setOpenMenu(false);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const droppable = (uid: string, data: any) => (
    <div onClick={() => setEditModalOpen(true)}>
      <Droppable id={uid} data={data}></Droppable>
    </div>
  );

  const columns: any = getColumns(data);
  const dataSource = getDataSource(data, filter, draggable, droppable);

  const handleDragEnd = async (event: any) => {
    const active = event?.active?.data?.current;
    const over = event?.over?.data?.current;
    if (over && active?.reservation?.checkoutAt && !isAdmin) {
      message.warning("Reservaciones con checkout no se pueden modificar.");
      return;
    }
    if (!over) {
      openReservationModal(active?.reservation);
    } else {
      setDragObject({ active, over });
    }
  };

  const handleUpdateReservation = async ({ active, over }: any) => {
    try {
      setLoading(true);
      const timelineReservationObject = getTimelineReservation(active, over);
      await updateTimelineReservation(timelineReservationObject);
      await fetchData();
      message.success("Reservación actualizada!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setDragObject(null);
      setLoading(false);
    }
  };

  const openReservationModal = (reservation: any) => {
    const data = {
      ...reservation,
      id: reservation.reservationId,
      initialDate: getDateStringDatabase(getDate(reservation.initialDate)),
      finalDate: getDateStringDatabase(getDate(reservation.finalDate)),
    };
    setSelectedReservation(data);
    setEditModalOpen(true);
  };

  const onFilterClicked = (filter: any) => {
    setLoading(true);
    setFilter(filter);
  };

  const onReload = async () => {
    await fetchData();
    onModalClose();
  };

  const onModalClose = () => {
    setEditModalOpen(false);
    setSelectedReservation(null);
  };

  return (
    <Layout>
      <Drawer
        key={"bottom"}
        placement={"bottom"}
        closable
        closeIcon={false}
        height={150}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        <TimelineToolbar
          filter={filter}
          onFilterClicked={onFilterClicked}
          editModalOpen={() => setEditModalOpen(true)}
        />
      </Drawer>

      <Content>
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
        >
          <Table
            rowClassName="row-class"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowKey={(record) => record.id}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        </DndContext>

        {editModalOpen && (
          <TimelineModal
            open={editModalOpen}
            isAdmin={isAdmin}
            reservation={selectedReservation}
            onReload={() => onReload()}
            onCancel={() => onModalClose()}
          ></TimelineModal>
        )}

        {dragObject && (
          <Modal
            title={`Mover reservación`}
            open={dragObject}
            centered
            okText="Confirmar"
            cancelText="Cancelar"
            onOk={() => handleUpdateReservation(dragObject)}
            onCancel={() => setDragObject(null)}
          >
            <p>
              Esta seguro de modificar la reservación de{" "}
              <b>{dragObject?.active?.reservation?.userName}</b>?
            </p>
          </Modal>
        )}
      </Content>
      <FloatButton
        type="primary"
        icon={<FilterFilled />}
        onClick={() => setOpenMenu(true)}
      />
    </Layout>
  );
};

export default Timeline;
