import { Space, Tooltip } from "antd";
import { roomStatus } from "@constants/roomStatus";
import { Draggable } from "@components/Common/Draggable";
import { getCalendarDateFormat, getCalendarDayFormat } from "@utils/date";

export const draggable = (uuid: string, data: any) => {
  const { reservation, roomsCount } = data;
  return (
    <Draggable id={uuid} data={data}>
      <h4>
        {reservation.reservationIndex}/{roomsCount}
      </h4>
      <Tooltip title={reservation.roomName}>
        <h3 className="text-wrap">{reservation.userName}</h3>
      </Tooltip>
      <Space style={{ alignItems: "stretch" }}>
        {getRoomStatusBadge(reservation.roomStatus) || ""}
      </Space>
    </Draggable>
  );
};

export const getRoomStatusBadge = (status: any) => {
  if (!status) return;
  switch (status) {
    case roomStatus.VACANT:
      return <span className="room-status-badge">V</span>;
    case roomStatus.OCCUPIED:
      return <span className="room-status-badge">O</span>;
  }
};

export const getCalendarColumnDate = (date: string) => (
  <div className="calendar-date-class">
    {getCalendarDateFormat(date)} {getCalendarDayFormat(date)}
  </div>
);
