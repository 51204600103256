export const breadcrumbNameMap: Record<string, string> = {
  "/": "Home",
  "/timeline": "",
  "/reservations": "Reservaciones",
  "/reservations/form": "reservación",
  "/payments": "Pagos",
  "/payments/form": "pagos",
  "/checkins": "Checkins",
  "/rooms": "Habitaciones",
  "/rooms/form": "habitación",
  "/users": "Usuarios",
  "/users/form": "usuario",
  "/companies": "Empresas",
  "/companies/form": "empresa",
  "/salesReportByPaymentMethod": "Reporte de Venta por Método de Pago",
  "/salesReportByRooms": "Reporte de Venta por Habitaciónes",
  "/salesReportByRoomTypes": "Reporte de Venta por Tipo de Habitaciónes",
  "/reportByRoomTypeQuantity": "Reporte de Cantidad de Tipo de Habitaciónes Vendidas",
};
