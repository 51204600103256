import React from "react";
import { Button, Col, Row } from "antd";
import { IDateToolbarProps } from "./table";
import { getDate, getDateString } from "@utils/date";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { YEAR_FORMAT, DATE_DETAIL_FORMAT } from "@constants/date";

import moment from "moment";
import DatePicker from "@components/Common/DatePicker";

const DateToolbar: React.FC<IDateToolbarProps> = (props) => {
  const onFilterChange = (e: any) => {
    const date = getDateString(e);
    props.setFilteredDate(date);
  };

  const substractDay = () => {
    let date;
    if (props.yearOnly) {
      date = getDate(props.filteredDate)?.subtract(1, "year");
    } else {
      date = getDate(props.filteredDate)?.subtract(1, "days");
    }
    props.setFilteredDate(getDateString(date));
  };

  const addDay = () => {
    let date;
    if (props.yearOnly) {
      date = getDate(props.filteredDate)?.add(1, "year");
    } else {
      date = getDate(props.filteredDate)?.add(1, "days");
    }
    props.setFilteredDate(getDateString(date));
  };

  return (
    <Row>
      <Col style={{ display: "flex" }} flex={1}>
        <Button
          type="primary"
          icon={<LeftOutlined />}
          size="large"
          onClick={() => substractDay()}
        />
        <DatePicker
          size="large"
          picker={props.yearOnly ? "year" : "date"}
          style={{ width: 200, marginLeft: 10, marginRight: 10 }}
          format={props.yearOnly ? YEAR_FORMAT : DATE_DETAIL_FORMAT}
          onChange={onFilterChange}
          value={getDate(props.filteredDate) || moment()}
        />
        <Button
          type="primary"
          icon={<RightOutlined />}
          size="large"
          onClick={() => addDay()}
        />
      </Col>
    </Row>
  );
};

export default DateToolbar;
