import React, { useEffect, useState } from "react";
import { ICheckinProps } from "./table";
import { getTodayLong } from "@utils/date";
import { getUser } from "@services/userService";
import { getErrorMessage } from "@utils/errorMessage";
import { ClockCircleTwoTone } from "@ant-design/icons";
import { Modal, Button, Tooltip, Spin, message, Card } from "antd";
import { getRoomsByReservationId } from "@services/reservationService";
import { createCheckin, updateCheckin } from "@services/checkinService";

import UserForm from "@pages/Users/UserForm";

const CheckinComponent: React.FC<ICheckinProps> = (props) => {
  const [rooms, setRooms] = useState<any>();
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [checkinOpen, setCheckinOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  const reservation = props.dataObject;

  useEffect(() => {
    if (!reservation || (!checkinOpen && !checkoutOpen)) return;
    fetchRoomsByReservationId(reservation.id);
  }, [reservation, checkinOpen, checkoutOpen]);

  const fetchRoomsByReservationId = async (id: string) => {
    try {
      setLoading(true);
      const rooms = await getRoomsByReservationId(id);
      setRooms(rooms);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return {};
    } finally {
      setLoading(false);
    }
  };

  const onCheckin = async () => {
    try {
      setLoading(true);
      const isValid = await isUserDataValid();
      if (!isValid) {
        setUserModalOpen(true);
      } else {
        await createCheckin(reservation.id);
        props.onReload();
        setCheckinOpen(false);
        message.success("Checkin realizado exitosamente!");
      }
    } catch (error: any) {
      setCheckinOpen(false);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const isUserDataValid = async () => {
    try {
      const user = await getUser(reservation.userId);
      setUser(user);

      const { dni, passport, city, country, phone } = user;
      if (!city || !country || !phone) {
        return false;
      }

      return dni || passport;
    } catch (error) {
      message.error(getErrorMessage(error));
      return false;
    }
  };

  const onCheckout = async () => {
    try {
      setLoading(true);
      await updateCheckin(reservation.id);
      props.onReload();
      setCheckoutOpen(false);
      message.success("Checkout realizado exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getModalHeader = (message: string) => (
    <div className="info-modal">
      <div className="info-modal-title">
        <h2 style={{ marginTop: 0 }}>{message}</h2>
      </div>
    </div>
  );

  return (
    <div>
      {!reservation.checkinAt && (
        <Tooltip title="Checkin">
          <Button
            ghost
            className="ghost-button"
            icon={
              <ClockCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => setCheckinOpen(true)}
              />
            }
          />
        </Tooltip>
      )}

      {reservation.checkinAt && !reservation.checkoutAt && (
        <Tooltip title="Checkout">
          <Button
            ghost
            className="ghost-button"
            icon={
              <ClockCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => setCheckoutOpen(true)}
              />
            }
          />
        </Tooltip>
      )}

      {checkinOpen && (
        <Modal
          title={getModalHeader("Desea realizar checkin?")}
          open={checkinOpen}
          centered
          width={props.showDetails ? 800 : 400}
          onCancel={() => setCheckinOpen(false)}
          onOk={() => onCheckin()}
          okText="Checkin"
        >
          {props.showDetails && (
            <Spin className="spinner" spinning={loading}>
              <Card title="Detalles">
                <Card.Grid className="table-row-detail">
                  <b>Fecha</b>
                  <br />
                  <span>{getTodayLong()}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Nombre</b>
                  <br />
                  <span>{reservation.userName}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Teléfono</b>
                  <br />
                  <span>{`${reservation.userPhonePrefix} ${reservation.userPhone}`}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Huespedes</b>
                  <br />
                  <span>{reservation.guests}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Notas </b>
                  <br />
                  <span>{reservation.notes}</span>
                </Card.Grid>
              </Card>
              <br />
              <Card title="Habitaciones">
                {rooms?.map((room: any) => (
                  <Card.Grid key={room.id} className="table-row-detail">
                    <b>{room.name}</b>
                    <br />
                    <span>Tipo: {room.type}</span>
                    <br />
                    <span>Huesped: {room.guest}</span>
                    <br />
                    <span>Precio: {room.price}</span>
                    <br />
                    <span>Estado: {room.status}</span>
                  </Card.Grid>
                ))}
              </Card>
            </Spin>
          )}
        </Modal>
      )}

      {checkoutOpen && (
        <Modal
          title={getModalHeader("Desea realizar checkout?")}
          open={checkoutOpen}
          centered
          width={props.showDetails ? 800 : 400}
          onCancel={() => setCheckoutOpen(false)}
          onOk={() => onCheckout()}
          okText="Checkout"
        >
          {props.showDetails && (
            <Spin className="spinner" spinning={loading}>
              <Card title="Detalles">
                <Card.Grid className="table-row-detail">
                  <b>Fecha</b>
                  <br />
                  <span>{getTodayLong()}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Nombre</b>
                  <br />
                  <span>{reservation.userName}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Teléfono</b>
                  <br />
                  <span>{`${reservation.userPhonePrefix} ${reservation.userPhone}`}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Huespedes</b>
                  <br />
                  <span>{reservation.guests}</span>
                </Card.Grid>
                <Card.Grid className="table-row-detail">
                  <b>Notas </b>
                  <br />
                  <span>{reservation.notes}</span>
                </Card.Grid>
              </Card>
              <br />
              <Card title="Habitaciones">
                {rooms?.map((room: any) => (
                  <Card.Grid key={room.id} className="table-row-detail">
                    <b>{room.name}</b>
                    <br />
                    <span>Tipo: {room.type}</span>
                    <br />
                    <span>Huesped: {room.guest}</span>
                    <br />
                    <span>Precio: {room.price}</span>
                    <br />
                    <span>Estado: {room.status}</span>
                  </Card.Grid>
                ))}
              </Card>
            </Spin>
          )}
        </Modal>
      )}

      {userModalOpen && (
        <Modal
          title="Actualizar Cliente"
          open={userModalOpen}
          centered
          width={600}
          footer={[]}
          onCancel={() => setUserModalOpen(false)}
        >
          <UserForm
            excludeFields
            includeRequiredFields
            dataObject={user}
            onReload={() => setUserModalOpen(false)}
            onCancel={() => setUserModalOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default CheckinComponent;
