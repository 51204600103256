import React, { Key, useEffect, useState } from "react";
import ICompany from "@interfaces/ICompany";
import TableToolbar from "@components/Table/TableToolbar";
import ActionComponent from "@components/Table/ActionComponent";

import { Table, Layout, Card, message } from "antd";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import type { ColumnsType, TableProps } from "antd/es/table";
import { deleteCompany, getCompanies } from "@services/companyService";

const { Header, Content } = Layout;

const CompaniesTable: React.FC = () => {
  const [data, setData] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ICompany>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const companyData = await getCompanies();
      setData(companyData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<ICompany> = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      key: "rtn",
      title: "RTN",
      dataIndex: "rtn",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => sortAlphaNum(a.rtn, b.rtn),
      sortOrder: sortedInfo.columnKey === "rtn" ? sortedInfo.order : null,
    },
    {
      key: "phone",
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.phone, b.phone),
      sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
    },
    {
      key: "email",
      title: "Correo",
      dataIndex: "email",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.email, b.email),
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (company: ICompany) => (
        <ActionComponent
          label={company.name}
          dataObject={company}
          onDelete={onDelete}
          disabled={false}
          showEdit
          showDelete
        />
      ),
    },
  ];

  const handleTableChange: TableProps<ICompany>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<ICompany>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  const onDelete = async (company: ICompany) => {
    try {
      setLoading(true);
      await deleteCompany(company.id);
      await fetchData();
      message.success("Company borrado exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const defaultExpandable = {
    expandedRowRender: (record: any) => (
      <>
        <Card>
          <Card.Grid className="table-row-detail">
            <b>Dirección </b>
            <br />
            <span>{record.address}</span>
          </Card.Grid>
          <Card.Grid hoverable={false} className="table-row-detail">
            <b>Ciudad </b> <br />
            <span>{record.city}</span>
          </Card.Grid>
          <Card.Grid className="table-row-detail">
            <b>País </b> <br /> <span>{record.country}</span>
          </Card.Grid>
        </Card>
      </>
    ),
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            expandable={defaultExpandable}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default CompaniesTable;
