import React, { useEffect, useState } from "react";
import IUser from "@interfaces/IUser";
import CountryLib from "countrycitystatejson";
import IFieldData from "@interfaces/IFieldData";
import DatePicker from "@components/Common/DatePicker";
import PhonePrefix from "@components/Common/PhonePrefix";

import { ROLE } from "@constants/role";
import { isUserAdmin } from "@utils/user";
import { DATE_FORMAT } from "@constants/date";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser, updateUser } from "@services/userService";
import { checkIfWithinLast100Years, getDateDatabase } from "@utils/date";
import {
  checkIfCodeExists,
  getHondurasCities,
  validatePassportNumber,
} from "@utils/util";
import {
  buttonLayout,
  cardFormLayout,
  formLayout,
} from "@constants/formLayout";
import {
  renderItem,
  IFormProps,
  filterData,
  IRenderItem,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";
import {
  Form,
  Input,
  Select,
  Space,
  message,
  Button,
  Spin,
  Card,
  Radio,
  RadioChangeEvent,
} from "antd";

const Identification = {
  DNI: "DNI",
  PASSPORT: "Pasaporte",
};

const UserForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isAdmin, setIsAdmin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [cityOptions, setCityOptions] = useState<IRenderItem[]>([]);
  const [identityType, setIdentityType] = useState(Identification.DNI);
  const [countryOptions, setCountryOptions] = useState<IRenderItem[]>([]);

  const [form] = Form.useForm();

  const getUser = () => {
    if (props.dataObject) {
      return props.dataObject;
    } else if (!props.excludeFields) {
      return location?.state?.dataObject;
    } else {
      return null;
    }
  };

  const user: IUser = getUser();

  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCountriesData();
    loadUser();
    checkIfUserIsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfUserIsAdmin = async () => {
    const isAdmin = await isUserAdmin();
    setIsAdmin(isAdmin);
  };

  const loadCountriesData = async () => {
    const countries = CountryLib.getCountries();
    const countryOptions: IRenderItem[] = countries?.map((country: any) => {
      return renderItem({ id: country.shortName, name: country.name });
    });
    setCountryOptions(countryOptions);
    loadCities("HN");
  };

  const loadCities = (shortName: string) => {
    let cities: any;
    if (shortName === "HN") {
      cities = getHondurasCities();
    } else {
      const country = CountryLib.getCountryByShort(shortName);
      cities = Object.values(country.states).flatMap((x) => x);
    }
    const citiesOptions: IRenderItem[] = cities?.map((city: any) => {
      return renderItem({ id: city.name, name: city.name });
    });
    setCityOptions(citiesOptions);
  };

  const loadUser = async () => {
    if (!user) return;
    setLoading(true);
    user.birthday = getDateDatabase(user.birthday);
    user.identityType = user.identityType || Identification.DNI;
    const userData = getFieldData(user);
    setFields(userData);
    setIdentityType(user.identityType);
    setLoading(false);
  };

  const goBack = (userId: string = "") =>
    props.onReload ? props.onReload(userId) : navigate("..");
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (userData: IUser) => {
    try {
      setLoading(true);
      let newUserId;
      if (user?.id) {
        await updateUser(userData);
        message.success("Usuario actualizado!");
      } else {
        newUserId = await createUser(userData);
        message.success("Usuario creado!");
      }
      goBack(newUserId);
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onIdentityChange = (e: RadioChangeEvent) => {
    setIdentityType(e.target.value);
  };

  const handleCountryChange = (_: any, value: any) => {
    form.setFieldsValue({ city: "" });
    loadCities(value.key);
  };

  const shouldShowPasswordField = (_: any, option: any) => {
    if (option.value !== ROLE.GUEST) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card {...cardFormLayout} bordered={false}>
        <Form
          {...formLayout}
          form={form}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
          initialValues={{ identityType: Identification.DNI }}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="uid" hidden />
          <Form.Item name="role" hidden />
          <Form.Item
            name="name"
            label="Nombre completo"
            rules={[{ required: true }]}
          >
            <Input className="name-input" maxLength={50} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Teléfono"
            rules={[
              {
                max: 15,
                required: true,
                message: "Teléfono debe de contener 15 caracteres",
              },
            ]}
          >
            <Input addonBefore={<PhonePrefix />} maxLength={15} />
          </Form.Item>

          {(!props.excludeFields || props.includeRequiredFields) && (
            <>
              <Form.Item
                label="Tipo de identidad"
                name="identityType"
                rules={[{ required: props.includeRequiredFields }]}
                initialValue={Identification.DNI}
              >
                <Radio.Group
                  onChange={onIdentityChange}
                  value={identityType}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio value={Identification.DNI}>{Identification.DNI}</Radio>
                  <Radio value={Identification.PASSPORT}>
                    {Identification.PASSPORT}
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {identityType === Identification.DNI && (
                <Form.Item
                  name="dni"
                  label="DNI"
                  rules={[
                    { required: props.includeRequiredFields },
                    () => ({
                      validator(_, value) {
                        if (!props.includeRequiredFields)
                          return Promise.resolve();
                        if (value?.length === 13) {
                          const code = value.substring(0, 4);
                          const year = value.substring(4, 8);
                          if (
                            checkIfCodeExists(code) &&
                            checkIfWithinLast100Years(year)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Format de DNI incorrecto")
                          );
                        } else {
                          return Promise.reject(
                            new Error("DNI debe tener 13 caracteres")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input maxLength={13} type="number" className="no-spinner" />
                </Form.Item>
              )}

              {identityType === Identification.PASSPORT && (
                <Form.Item
                  name="passport"
                  label="Pasaporte"
                  rules={[
                    { required: props.includeRequiredFields },
                    () => ({
                      validator(_, value) {
                        if (value?.length > 5) {
                          if (validatePassportNumber(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Format de pasaporte incorrecto")
                          );
                        } else {
                          return Promise.reject(
                            new Error(
                              "Pasaporte debe tener minimo 6 caracteres"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input maxLength={20} />
                </Form.Item>
              )}
            </>
          )}

          {!props.excludeFields && (
            <>
              <Form.Item
                name="email"
                label="Correo"
                rules={[
                  {
                    type: "email",
                    message: "Correo no es valido.",
                    required: showPassword,
                  },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item name="gender" label="Género">
                <Select>
                  <Select.Option value="Femenino">Femenino</Select.Option>
                  <Select.Option value="Masculino">Masculino</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="birthday" label="Nacimiento">
                <DatePicker
                  format={DATE_FORMAT}
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder="Elegir fecha"
                />
              </Form.Item>

              <Form.Item name="role" label="Role" initialValue={ROLE.GUEST}>
                <Select onChange={shouldShowPasswordField}>
                  <Select.Option value={ROLE.GUEST}>{ROLE.GUEST}</Select.Option>
                  {isAdmin && (
                    <>
                      <Select.Option value={ROLE.RECEPCIONIST}>
                        {ROLE.RECEPCIONIST}
                      </Select.Option>
                      <Select.Option value={ROLE.ADMIN}>
                        {ROLE.ADMIN}
                      </Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>

              {!user?.id && showPassword && (
                <Form.Item
                  name="password"
                  label="Contraseña"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input maxLength={30} />
                </Form.Item>
              )}
            </>
          )}

          {(!props.excludeFields || props.includeRequiredFields) && (
            <>
              <Form.Item
                name="city"
                label="Ciudad"
                rules={[{ required: props.includeRequiredFields }]}
              >
                <Select
                  showSearch
                  options={cityOptions}
                  notFoundContent="Ciudad no encontrado"
                  filterOption={filterData}
                ></Select>
              </Form.Item>

              <Form.Item
                name="country"
                label="País"
                initialValue="Honduras"
                rules={[{ required: props.includeRequiredFields }]}
              >
                <Select
                  showSearch
                  options={countryOptions}
                  onChange={handleCountryChange}
                  notFoundContent="País no encontrado"
                  filterOption={filterData}
                ></Select>
              </Form.Item>
            </>
          )}

          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {user?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default UserForm;
