import React, { Key, useEffect, useState } from "react";

import { Moment } from "moment";
import { getErrorMessage } from "@utils/errorMessage";
import { SorterResult } from "antd/es/table/interface";
import { getStatusColor } from "@components/Table/table";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Table, Layout, Card, message, Row, Col, Tag } from "antd";
import {
  getToday,
  getDateString,
  getTimeString,
  getDateDifference,
} from "@utils/date";
import {
  getReservations,
  deleteReservation,
  getRoomsByReservationId,
} from "@services/reservationService";

import IRoom from "@interfaces/IRoom";
import IReservation from "@interfaces/IReservation";
import DateToolbar from "@components/Table/DateToolbar";
import TableToolbar from "@components/Table/TableToolbar";
import ActionComponent from "@components/Table/ActionComponent";
import CheckinComponent from "@components/Table/CheckinComponent";

const { Header, Content } = Layout;

const ReservationsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IReservation[]>([]);
  const [selectedRowRooms, setSelectedRowRooms] = useState<IRoom[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IReservation>>({});
  const [filteredString, setFilteredString] = useState<Key>("");
  const [selectedRowKey, setSelectedRowKey] = useState<any>([]);
  const [filteredDate, setFilteredDate] = useState<any>(getToday());

  useEffect(() => {
    fetchData(filteredDate);
  }, [filteredDate]);

  const fetchData = async (filteredDate: Moment) => {
    try {
      setLoading(true);
      const reservationData = await getReservations(filteredDate);
      setData(reservationData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<IReservation> = [
    {
      key: "initialDate",
      title: "Fecha de llegada",
      dataIndex: "initialDate",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => getDateDifference(a.initialDate, b.initialDate),
      sortOrder:
        sortedInfo.columnKey === "initialDate" ? sortedInfo.order : null,
      render: (initialDate) => getDateString(initialDate),
    },
    {
      key: "finalDate",
      title: "Fecha de salida",
      dataIndex: "finalDate",
      align: "center",
      sorter: (a, b) => getDateDifference(a.finalDate, b.finalDate),
      sortOrder: sortedInfo.columnKey === "finalDate" ? sortedInfo.order : null,
      render: (finalDate) => getDateString(finalDate),
    },
    {
      key: "arrivalTime",
      title: "Hora de llegada",
      dataIndex: "arrivalTime",
      align: "center",
      sorter: (a, b) => getDateDifference(a.arrivalTime, b.arrivalTime),
      sortOrder:
        sortedInfo.columnKey === "arrivalTime" ? sortedInfo.order : null,
      render: (arrivalTime) => getTimeString(arrivalTime),
    },
    {
      key: "userName",
      title: "Cliente",
      dataIndex: "userName",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.userName, b.userName),
      sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.status, b.status),
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (reservation: IReservation) => (
        <Row align="middle" justify="center">
          <Col>
            <CheckinComponent
              showDetails={true}
              dataObject={reservation}
              onReload={onReload}
            />
          </Col>
          <Col>
            <ActionComponent
              label="reservación"
              dataObject={reservation}
              onDelete={onDelete}
              disabled={false}
              showEdit
              showDelete
            />
          </Col>
        </Row>
      ),
    },
  ];

  const handleTableChange: TableProps<IReservation>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IReservation>);
  };

  const onDelete = async (reservation: IReservation) => {
    try {
      setLoading(true);
      await deleteReservation(reservation.id);
      await fetchData(filteredDate);
      message.success("Reservación borrada exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onReload = async () => {
    setLoading(true);
    await fetchData(filteredDate);
    setLoading(false);
  };

  const expandableComponent = {
    expandedRowKeys: selectedRowKey,
    onExpand: async (expanded: boolean, record: any) => {
      if (expanded) {
        const rooms = await getRoomsByReservationId(record.id);
        setSelectedRowRooms(rooms);
        setSelectedRowKey([record.id]);
      } else {
        setSelectedRowKey([]);
      }
    },
    expandedRowRender: (record: any, a: any, b: any) => (
      <>
        <Card title="Detalles">
          <Card.Grid className="table-row-detail">
            <b>Huespedes </b>
            <br />
            <span>{record.guests}</span>
          </Card.Grid>
          <Card.Grid className="table-row-detail">
            <b>Empresa </b>
            <br />
            <span>{record.companyId}</span>
          </Card.Grid>
          <Card.Grid className="table-row-detail">
            <b>Notas </b>
            <br />
            <span>{record.notes}</span>
          </Card.Grid>
        </Card>
        <br />
        <Card title="Habitaciones">
          {selectedRowRooms?.map((room: any) => (
            <Card.Grid key={room.id} className="table-row-detail">
              <b>{room.name}</b>
              <br />
              <span>Tipo: {room.type}</span>
              <br />
              <span>Huesped: {room.guest}</span>
              <br />
              <span>Precio: {room.price}</span>
              <br />
              <span>Estado: {room.status}</span>
            </Card.Grid>
          ))}
        </Card>
      </>
    ),
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <Row>
            <Col style={{ display: "flex" }} flex={0.5}>
              <DateToolbar
                yearOnly={true}
                filteredDate={filteredDate}
                setFilteredDate={setFilteredDate}
              />
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} flex={12}>
              <TableToolbar
                onFilterChange={setFilteredString}
                canCreate={true}
              />
            </Col>
          </Row>
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            expandable={expandableComponent}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default ReservationsTable;
