import axios from "axios";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/checkin";

export const getCheckin = async (id: string) => {
  const headers = await getConfigHeaders();
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching checkin:", error);
    throw error;
  }
};

export const getCheckins = async () => {
  const headers = await getConfigHeaders();
  try {
    const response = await axios.get(`${API_BASE_URL}/all`, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching checkins:", error);
    throw error;
  }
};

export const createCheckin = async (id: string) => {
  const headers = await getConfigHeaders();
  try {
    const response = await axios.post(
      `${API_BASE_URL}/checkin`,
      { reservationId: id },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error creating checkin:", error);
    throw error;
  }
};

export const updateCheckin = async (id: string) => {
  const headers = await getConfigHeaders();
  try {
    const response = await axios.put(
      `${API_BASE_URL}/checkout`,
      { reservationId: id },
      headers
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCheckin = async (reservationId: string) => {
  const headers = await getConfigHeaders();
  try {
    const response = await axios.delete(`${API_BASE_URL}/${reservationId}`, headers);
    return response.data;
  } catch (error) {
    console.error("Error deleting checkin:", error);
    throw error;
  }
};
