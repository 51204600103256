import axios from "axios";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/timeline";

export const getTimeline = async ({ date }: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(`${API_BASE_URL}/`, { date }, headers);
    return response.data;
  } catch (error) {
    console.error("Error fetching timeline:", error);
    throw error;
  }
};

export const updateTimelineReservation = async (data: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/updateTimelineReservation`,
      data,
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error updating timeline reservation:", error);
    throw error;
  }
};
