import React, { Key, useEffect, useState } from "react";
import ICheckin from "@interfaces/ICheckin";
import TableToolbar from "@components/Table/TableToolbar";
import ActionComponent from "@components/Table/ActionComponent";

import { Table, Layout, Card, message } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { getDateDifference, getDateTimeString } from "@utils/date";
import { deleteCheckin, getCheckins } from "@services/checkinService";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import type { ColumnsType, TableProps } from "antd/es/table";
import { getErrorMessage } from "@utils/errorMessage";

const { Header, Content } = Layout;

const CheckinsTable: React.FC = () => {
  const [data, setData] = useState<ICheckin[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ICheckin>>({});
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const checkinData = await getCheckins();
      setData(checkinData);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<ICheckin> = [
    {
      key: "checkinAt",
      title: "Checkin",
      dataIndex: "checkinAt",
      align: "center",
      filteredValue: [filteredString],
      onFilter: (value, record) => isFilterMatched(value, record),
      sorter: (a, b) => getDateDifference(a.checkinAt, b.checkinAt),
      sortOrder: sortedInfo.columnKey === "checkinAt" ? sortedInfo.order : null,
      render: (checkinAt) => getDateTimeString(checkinAt),
    },
    {
      key: "checkoutAt",
      title: "Checkout",
      dataIndex: "checkoutAt",
      align: "center",
      sorter: (a, b) => getDateDifference(a.checkoutAt, b.checkoutAt),
      sortOrder:
        sortedInfo.columnKey === "checkoutAt" ? sortedInfo.order : null,
      render: (checkoutAt) => getDateTimeString(checkoutAt),
    },
    {
      key: "userName", 
      title: "Nombre",
      dataIndex: "userName",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.userName, b.userName),
      sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
    },
    {
      key: "companyName",
      title: "Empresa",
      dataIndex: "companyName",
      align: "center",
      sorter: (a, b) => sortAlphaNum(a.companyName, b.companyName),
      sortOrder: sortedInfo.columnKey === "companyName" ? sortedInfo.order : null,
    },
    {
      key: "x",
      dataIndex: "",
      title: "Acción",
      align: "center",
      width: 100,
      render: (checkin: ICheckin) => (
        <ActionComponent
          label="checkin"
          dataObject={checkin}
          onDelete={onDelete}
          disabled={false}
          showEdit={false}
          showDelete
        />
      ),
    },
  ];

  const onDelete = async (checkin: ICheckin) => {
    try {
      setLoading(true);
      await deleteCheckin(checkin.reservationId);
      await fetchData();
      message.success("Checkin borrado exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange: TableProps<ICheckin>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<ICheckin>);
  };

  const handleFilterChange = (value: string) => {
    setFilteredString(value);
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={handleFilterChange} canCreate={false} />
        </Header>
        <Content>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
            pagination={{ pageSize: 20 }}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default CheckinsTable;
