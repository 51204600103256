import axios from "axios";
import ICompany from "@interfaces/ICompany";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/company";

export const getCompany = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanies = async () => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.get(`${API_BASE_URL}/all`, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCompany = async (companyData: ICompany) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/create`,
      companyData,
      headers
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompany = async (companyData: ICompany) => {
  try {
    const { id } = companyData;
    const headers = await getConfigHeaders();
    const response = await axios.put(
      `${API_BASE_URL}/${id}`,
      companyData,
      headers
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCompany = async (id: string) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};
