import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "@pages/Login";
import ResetPassword from "@pages/Login/ResetPassword";
import Timeline from "@pages/Timeline";
import Checkins from "@pages/Checkins";
import Rooms from "@pages/Rooms";
import RoomForm from "@pages/Rooms/RoomForm";
import Users from "@pages/Users";
import UserForm from "@pages/Users/UserForm";
import Companies from "@pages/Companies";
import CompanyForm from "@pages/Companies/CompanyForm";
import Reservations from "@pages/Reservations";
import ReservationForm from "@pages/Reservations/ReservationForm";
import Payments from "@pages/Payments";
import SalesReportByPaymentMethod from "@pages/Reports/SalesReportByPaymentMethod";
import SalesReportByRooms from "@pages/Reports/SalesReportByRooms";
import SalesReportByRoomTypes from "@pages/Reports/SalesReportByRoomTypes";
import ReportByRoomTypeQuantity from "@pages/Reports/ReportByRoomTypeQuantity";
import Support from "@pages/Support";

export const PublicContent: React.FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export const PrivateContent: React.FC<any> = ({ isAdmin }) => {
  return (
    <Routes>
      <Route path="/" element={<Timeline />} />
      <Route path="timeline" element={<Timeline />} />
      <Route path="reservations">
        <Route index element={<Reservations />} />
        <Route path="form" element={<ReservationForm />} />
      </Route>
      <Route path="payments">
        <Route index element={<Payments />} />
        <Route path="form" element={<ReservationForm />} />
      </Route>
      <Route path="checkins">
        <Route index element={<Checkins />} />
      </Route>
      <Route path="rooms">
        <Route index element={<Rooms />} />
        <Route path="form" element={<RoomForm />} />
      </Route>
      <Route path="users">
        <Route index element={<Users />} />
        <Route path="form" element={<UserForm />} />
      </Route>
      <Route path="companies">
        <Route index element={<Companies />} />
        <Route path="form" element={<CompanyForm />} />
      </Route>
      {isAdmin && (
        <Route path="salesReportByPaymentMethod">
          <Route index element={<SalesReportByPaymentMethod isAdmin={isAdmin} />} />
        </Route>
      )}
      {isAdmin && (
        <Route path="salesReportByRooms">
          <Route index element={<SalesReportByRooms isAdmin={isAdmin} />} />
        </Route>
      )}
      {isAdmin && (
        <Route path="salesReportByRoomTypes">
          <Route index element={<SalesReportByRoomTypes isAdmin={isAdmin} />} />
        </Route>
      )}
      {isAdmin && (
        <Route path="reportByRoomTypeQuantity">
          <Route index element={<ReportByRoomTypeQuantity isAdmin={isAdmin} />} />
        </Route>
      )}
      <Route path="support">
        <Route index element={<Support />} />
      </Route>
    </Routes>
  );
};
