import React from "react";
import { IPaymentProps } from "./table";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip, message } from "antd";
import { getErrorMessage } from "@utils/errorMessage";
import { approvePayment } from "@services/reservationService";
import { EditFilled, CheckCircleTwoTone } from "@ant-design/icons";

const ApproveComponent: React.FC<IPaymentProps> = (props) => {
  const navigate = useNavigate();
  const payment = props.dataObject;

  const onApprove = async () => {
    try {
      props.setLoading(true);
      await approvePayment(payment.paymentId);
      await props.onReload();
      message.success("Aprobado");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      props.setLoading(false);
    }
  };

  const onEdit = () => {
    navigate("form", {
      state: { dataObject: payment },
    });
  };

  return (
    <>
      {!payment?.approved && (
        <Tooltip title="Aprobar">
          <Button
            ghost
            className="ghost-button"
            icon={
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onApprove()}
              />
            }
          />
        </Tooltip>
      )}
      <Tooltip title="Editar">
        <Button
          ghost
          className="ghost-button"
          icon={<EditFilled onClick={() => onEdit()} />}
        />
      </Tooltip>
    </>
  );
};

export default ApproveComponent;
