import React, { useEffect, useState } from "react";
import ICompany from "@interfaces/ICompany";
import IFieldData from "@interfaces/IFieldData";
import PhonePrefix from "@components/Common/PhonePrefix";
import CountryLib from "countrycitystatejson";

import { getHondurasCities } from "@utils/util";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { createCompany, updateCompany } from "@services/companyService";
import {
  formLayout,
  buttonLayout,
  cardFormLayout,
} from "@constants/formLayout";
import {
  renderItem,
  IFormProps,
  IRenderItem,
  filterData,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";import {
  Card,
  Form,
  Input,
  Select,
  Space,
  message,
  Button,
  Spin,
  Switch,
} from "antd";

const CompanyForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const company: ICompany = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);
  const [isForeign, setIsForeign] = useState(false);
  const [cityOptions, setCityOptions] = useState<IRenderItem[]>([]);
  const [countryOptions, setCountryOptions] = useState<IRenderItem[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    loadCountriesData();
    loadCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCountriesData = async () => {
    const countries = CountryLib.getCountries();
    const countryOptions: IRenderItem[] = countries?.map((country: any) => {
      return renderItem({ id: country.shortName, name: country.name });
    });
    setCountryOptions(countryOptions);
    loadCities("HN");
  };

  const loadCities = (shortName: string) => {
    let cities: any;
    if (shortName === "HN") {
      cities = getHondurasCities();
    } else {
      const country = CountryLib.getCountryByShort(shortName);
      cities = Object.values(country.states).flatMap((x) => x);
    }
    const citiesOptions: IRenderItem[] = cities?.map((city: any) => {
      return renderItem({ id: city.name, name: city.name });
    });
    setCityOptions(citiesOptions);
  };

  const loadCompany = () => {
    if (!company) return;
    setLoading(true);
    const companyData = getFieldData(company);
    setFields(companyData);
    setLoading(false);
  };

  const onChangeForeign = async (value: any) => {
    form.setFieldsValue({ rtn: null });
    setIsForeign(value);
  };

  const goBack = (companyId: string = "") =>
    props.onReload ? props.onReload(companyId) : navigate("..");
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (companyData: ICompany) => {
    try {
      setLoading(true);
      let newCompanyId;
      if (company?.id) {
        await updateCompany(companyData);
        message.success("Empresa actualizada!");
      } else {
        newCompanyId = await createCompany(companyData);
        message.success("Empresa creada!");
      }
      goBack(newCompanyId);
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (_: any, value: any) => {
    form.setFieldsValue({ city: "" });
    loadCities(value.key);
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card {...cardFormLayout} bordered={false}>
        <Form
          {...formLayout}
          form={form}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="name" label="Nombre" rules={[{ required: true }]} initialValue="Test">
            <Input className="name-input" maxLength={50} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Teléfono"
            rules={[
              {
                max: 15,
                required: true,
                message: "Teléfono debe de contener 15 caracteres",
              },
            ]}
          >
            <Input addonBefore={<PhonePrefix />} maxLength={15} />
          </Form.Item>

          <Form.Item
            name={"isForeign"}
            label={"Es extranjero?"}
            valuePropName="checked"
          >
            <Switch onChange={onChangeForeign} />
          </Form.Item>

          {!isForeign && (
            <Form.Item name="rtn" label="RTN" rules={[{ required: true }]}>
              <Input maxLength={20} />
            </Form.Item>
          )}

          {!props.excludeFields && (
            <>
              <Form.Item
                name="email"
                label="Correo"
                rules={[
                  {
                    type: "email",
                    message: "Correo no es valido.",
                  },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>

              <Form.Item name="address" label="Dirección">
                <Input maxLength={50} />
              </Form.Item>
            </>
          )}

          <Form.Item name="city" label="Ciudad" rules={[{ required: true }]}>
            <Select
              showSearch
              options={cityOptions}
              notFoundContent="Ciudad no encontrado"
              filterOption={filterData}
            ></Select>
          </Form.Item>

          <Form.Item
            name="country"
            label="País"
            initialValue="Honduras"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              options={countryOptions}
              onChange={handleCountryChange}
              notFoundContent="País no encontrado"
              filterOption={filterData}
            ></Select>
          </Form.Item>

          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {company?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default CompanyForm;
