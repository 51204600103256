import React from "react";
import moment from "moment";
import DatePicker from "@components/Common/DatePicker";

import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DATE_FORMAT, WEEK_FORMAT } from "@constants/date";
import { getFinalDate, getInitialDate } from "@utils/date";

const TimelineToolbar: React.FC<any> = (props) => {
  const { date } = props?.filter;

  const customWeekStartEndFormat = (value: any) =>
    `${getInitialDate(value).format(WEEK_FORMAT)} ~ ${getFinalDate(
      value
    ).format(`${WEEK_FORMAT} YYYY`)}`;

  const onChange = (value: any) =>
    props.onFilterClicked({ date: value.format(DATE_FORMAT) });

  return (
    <Row>
      <Col style={{ marginRight: "50px" }}>
        <DatePicker
          size="large"
          style={{ width: "320px" }}
          defaultValue={moment(date, DATE_FORMAT)}
          format={customWeekStartEndFormat}
          onChange={onChange}
        />
      </Col>

      <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => props.editModalOpen(true)}
          >
            Crear Reservación
          </Button>
      </Col>
    </Row>
  );
};

export default TimelineToolbar;
