import { roomStatus } from "@constants/roomStatus";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import {
  Col,
  Row,
  Form,
  Input,
  Space,
  Button,
  Select,
  Divider,
  InputNumber,
} from "antd";

const RoomDetails: React.FC<any> = (props) => {
  return (
    <>
      <Form.List name="rooms">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Form.Item
                key={key}
                style={{ marginBottom: 0 }}
                wrapperCol={{ span: 18, offset: 4 }}
              >
                <Row style={{ width: "80%", flexFlow: "nowrap" }}>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <Select showSearch options={props.roomOptions}></Select>
                    </Form.Item>
                    <Space.Compact style={{ width: "100%" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "status"]}
                        style={{ width: "60%" }}
                        initialValue={roomStatus.VACANT}
                      >
                        <Select>
                          <Select.Option value={roomStatus.VACANT}>
                            {roomStatus.VACANT}
                          </Select.Option>
                          <Select.Option value={roomStatus.OCCUPIED}>
                            {roomStatus.OCCUPIED}
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "price"]}
                        style={{ width: "40%" }}
                        initialValue={500}
                      >
                        <InputNumber
                          min={500}
                          controls={true}
                          addonAfter="lps."
                        />
                      </Form.Item>
                    </Space.Compact>

                    <Space.Compact style={{ width: "100%" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "guest"]}
                        style={{ width: "100%" }}
                      >
                        <Input
                          className="name-input"
                          placeholder="Nombre huesped"
                          maxLength={50}
                        />
                      </Form.Item>
                    </Space.Compact>
                  </Col>
                  <Col>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Divider style={{ marginTop: 0 }} />
              </Form.Item>
            ))}
            <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
              <Button
                block
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                style={{ color: "#760e19", borderColor: "#760e19" }}
              >
                Agregar habitación
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default RoomDetails;
