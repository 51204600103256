import { getAuth } from "firebase/auth";

export const getConfigHeaders = async () => {
  try {
    const auth: any = getAuth();
    await auth.currentUser.getIdToken(true);
    return {
      headers: {
        Authorization: `Bearer ${auth.currentUser.accessToken}`,
      },
    };
  } catch (error) {
  }
};
