import { Tag } from "antd";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";
import { getDateCountInRange } from "@utils/date";
import {
  getStatusColor,
  getStatusBackgroundColor,
} from "@components/Table/table";

export function Draggable(props: any) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: props.data,
  });

  const { reservation } = props?.data;
  const { dates } = reservation;
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];

  const daysCount =
    getDateCountInRange(startDate, endDate, props.data.givenDate) - 2;

  let width = 60;

  if (daysCount > 0) {
    width = daysCount * 120 + 110;
  } else if (daysCount === 0) {
    width = 110;
  }

  const statusColor = getStatusColor(reservation?.status);
  const statusBackgroundColor = getStatusBackgroundColor(reservation);

  const style: any = {
    width: width,
    // cursor: "pointer",
    transform: CSS.Translate.toString(transform),
    color: reservation?.companyName === "Booking" ? "white" : "black",
    backgroundColor: statusBackgroundColor,
    borderLeftColor: statusColor,
  };

  return (
    <Tag
      ref={setNodeRef}
      className="draggable-class"
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </Tag>
  );
}
