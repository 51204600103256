import React, { useEffect, useState } from "react";
import IRoom from "@interfaces/IRoom";
import IFieldData from "@interfaces/IFieldData";

import { roomTypes } from "@constants/roomTypes";
import { getErrorMessage } from "@utils/errorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { createRoom, updateRoom } from "@services/roomService";
import {
  buttonLayout,
  cardFormLayout,
  formLayout,
} from "@constants/formLayout";
import {
  Card,
  Form,
  Input,
  Select,
  Space,
  message,
  Button,
  Spin,
  InputNumber,
} from "antd";
import {
  IFormProps,
  getFieldData,
  onFinishFailed,
} from "@components/Form/form";

const RoomForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const room: IRoom = props.dataObject
    ? props.dataObject
    : location?.state?.dataObject;

  const [fields, setFields] = useState<IFieldData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRoom = () => {
    if (!room) return;
    setLoading(true);
    const roomData = getFieldData(room);
    setFields(roomData);
    setLoading(false);
  };

  const goBack = () => (props.onReload ? props.onReload() : navigate(".."));
  const handleCancel = () => (props.onCancel ? props.onCancel() : goBack());

  const onFinish = async (roomData: IRoom) => {
    try {
      setLoading(true);
      if (room?.id) {
        await updateRoom(roomData);
        message.success("Habitación actualizada!");
      } else {
        await createRoom(roomData);
        message.success("Habitación creada!");
      }
      goBack();
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card {...cardFormLayout} bordered={false}>
        <Form
          {...formLayout}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input className="name-input" maxLength={50} />
          </Form.Item>

          <Form.Item
            name="type"
            label="Tipo"
            rules={[{ required: true }]}
            initialValue={roomTypes.SINGLE}
          >
            <Select>
              <Select.Option value={roomTypes.SINGLE}>{roomTypes.SINGLE}</Select.Option>
              <Select.Option value={roomTypes.DOUBLE}>{roomTypes.DOUBLE}</Select.Option>
              <Select.Option value={roomTypes.TRIPLE}>{roomTypes.TRIPLE}</Select.Option>
              <Select.Option value={roomTypes.SUITE}>{roomTypes.SUITE}</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="price"
            label="Precio"
            rules={[{ required: true }]}
            initialValue={0}
          >
            <InputNumber controls={true} min={0} addonAfter="lps." />
          </Form.Item>

          <Form.Item
            name="status"
            label="Estado"
            rules={[{ required: true }]}
            initialValue="Disponible"
          >
            <Select>
              <Select.Option value="Disponible">Disponible</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item {...buttonLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                {room?.id ? "Editar" : "Crear"}
              </Button>
              <Button onClick={() => handleCancel()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default RoomForm;
