import React, { useState } from "react";
import { IActionProps } from "./table";
import { Button, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";

const ActionComponent: React.FC<IActionProps> = (props) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onEdit = () => {
    props.onConfirm
      ? props.onConfirm()
      : navigate("form", {
          state: { dataObject: props.dataObject },
        });
  };

  return (
    <div>
      {props.showEdit && (
        <Tooltip title="Editar">
          <Button
            ghost
            className="ghost-button"
            disabled={props.disabled}
            icon={<EditFilled onClick={() => onEdit()} />}
          />
        </Tooltip>
      )}

      {props.showDelete && (
        <Tooltip title="Borrar">
          <Button
            ghost
            className="ghost-button"
            icon={<DeleteOutlined onClick={() => setConfirmOpen(true)} />}
          />
        </Tooltip>
      )}

      <Modal
        title={`Borrar`}
        open={confirmOpen}
        centered
        okText="Confirmar"
        cancelText="No"
        onOk={() => props.onDelete(props.dataObject)}
        onCancel={() => setConfirmOpen(false)}
      >
        <p>Esta seguro de borrar {props.label}?</p>
      </Modal>
    </div>
  );
};

export default ActionComponent;
