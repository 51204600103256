import React, { useEffect, useState } from "react";
import { getErrorMessage } from "@utils/errorMessage";
import { Card, Col, Modal, Row, Spin, message } from "antd";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { getTimeString, getNumberOfDays, getLongDateString } from "@utils/date";
import {
  getReservation,
  deleteReservation,
  getRoomsByReservationId,
  getPaymentsByReservationId,
} from "@services/reservationService";

import ActionComponent from "@components/Table/ActionComponent";
import ReservationForm from "@pages/Reservations/ReservationForm";
import CheckinComponent from "@components/Table/CheckinComponent";
import { paymentStatus } from "@constants/paymentStatus";

const TimelineModal: React.FC<any> = (props) => {
  const [rooms, setRooms] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState<any>();
  const [editModalOpen, setEditModalOpen] = useState(!props?.reservation?.id);

  useEffect(() => {
    if (!props?.reservation?.id) return;
    const reservationId = props?.reservation?.id;
    fetchReservation(reservationId);
    fetchRoomsByReservationId(reservationId);
    fetchPaymentsByReservationId(reservationId);
  }, [props]);

  const fetchReservation = async (id: string) => {
    try {
      setLoading(true);
      const reservation = await getReservation(id);
      setReservation(reservation);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchRoomsByReservationId = async (id: string) => {
    try {
      setLoading(true);
      const rooms = await getRoomsByReservationId(id);
      setRooms(rooms);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentsByReservationId = async (id: string) => {
    try {
      setLoading(true);
      const payments = await getPaymentsByReservationId(id);
      debugger;
      setPayments(payments);
    } catch (error: any) {
      console.error(error);
      message.error(getErrorMessage(error));
      return {};
    } finally {
      setLoading(false);
    }
  };

  const closeAll = () => {
    setEditModalOpen(false);
    props.onCancel();
  };

  const onDelete = async () => {
    if (!reservation) return;
    try {
      await deleteReservation(reservation.id);
      props.onReload();
      message.success("Reservación borrada exitosamente!");
    } catch (error: any) {
      message.error(getErrorMessage(error));
    }
  };

  const getModalHeader = () => (
    <div className="info-modal">
      <div className="info-modal-title">
        <h2 style={{ marginTop: 0 }}>{reservation?.userName}</h2>
      </div>
      <div className="info-modal-actions">
        <Row>
          <Col>
            <CheckinComponent
              showDetails={false}
              dataObject={reservation}
              onReload={() => props.onReload()}
            />
          </Col>
          <Col>
            <ActionComponent
              label="reservación"
              dataObject={reservation}
              onDelete={onDelete}
              onConfirm={() => setEditModalOpen(true)}
              disabled={false}
              showEdit
              showDelete
            />
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <>
      {props.open && reservation?.id && (
        <Modal
          title={getModalHeader()}
          open={props.open}
          width={800}
          centered
          footer={[]}
          onCancel={() => {
            props.onCancel();
          }}
        >
          {reservation && (
            <>
              <Spin className="spinner" spinning={loading}>
                <Card title="Detalles">
                  <Card.Grid className="table-row-detail">
                    <b>Fechas </b>
                    <br />
                    <span>
                      {getLongDateString(reservation.initialDate)}
                      <br />
                      {getLongDateString(reservation.finalDate)}
                    </span>
                  </Card.Grid>
                  <Card.Grid className="table-row-detail">
                    <b>Noches </b>
                    <br />
                    <span>
                      {getNumberOfDays(
                        reservation.initialDate,
                        reservation.finalDate
                      )}
                    </span>
                  </Card.Grid>
                  <Card.Grid className="table-row-detail">
                    <b>Hora de llegada </b>
                    <br />
                    <span>{getTimeString(reservation.arrivalTime)}</span>
                  </Card.Grid>
                  <Card.Grid className="table-row-detail">
                    <b>Teléfono </b>
                    <br />
                    <span>
                      {reservation.userPhonePrefix} {reservation.userPhone}
                    </span>
                  </Card.Grid>
                  <Card.Grid className="table-row-detail">
                    <b>Huespedes </b>
                    <br />
                    <span>{reservation.guests}</span>
                  </Card.Grid>
                  <Card.Grid className="table-row-detail">
                    <b>Empresa </b>
                    <br />
                    <span>{reservation.companyName}</span>
                  </Card.Grid>
                </Card>
                <br />
                {rooms.length > 0 && (
                  <Card title="Habitaciones">
                    {rooms?.map((room: any) => (
                      <Card.Grid key={room.id} className="table-row-detail">
                        <b>{room.name}</b>
                        <br />
                        <span>Tipo: {room.type}</span>
                        <br />
                        <span>Huesped: {room.guest}</span>
                        <br />
                        <span>Precio: {room.price}</span>
                        <br />
                        <span>Estado: {room.status}</span>
                      </Card.Grid>
                    ))}
                  </Card>
                )}
                <br />
                {payments.length > 0 && (
                  <Card title="Pagos">
                    {payments?.map((payment: any) => (
                      <Card.Grid key={payment.id} className="table-row-detail">
                        <b>{payment.date}</b>
                        <br />
                        {payment.status === paymentStatus.PAID ? (
                          <CheckCircleFilled
                            style={{
                              color: "green",
                              fontSize: "24px",
                              marginTop: "3px",
                            }}
                          />
                        ) : (
                          <MinusCircleFilled
                            style={{
                              color: "red",
                              fontSize: "24px",
                              marginTop: "3px",
                            }}
                          />
                        )}
                      </Card.Grid>
                    ))}
                  </Card>
                )}
                <br />
                {reservation.notes && (
                  <Card title="Notas">
                    <span>{reservation.notes}</span>
                  </Card>
                )}
              </Spin>
            </>
          )}
        </Modal>
      )}

      {editModalOpen && (
        <Modal
          title={reservation?.id ? "Editar reservación" : "Crear reservación"}
          open={editModalOpen}
          centered
          width={1000}
          footer={[]}
          onCancel={() => closeAll()}
        >
          <ReservationForm
            dataObject={reservation}
            onReload={() => props.onReload()}
            onCancel={() => closeAll()}
          />
        </Modal>
      )}
    </>
  );
};

export default TimelineModal;
