import moment from "moment";
import { uniqBy } from "lodash";
import { getDateTimeString } from "./date";
import { citiesHonduras } from "@constants/citiesHonduras";

export const isFilterMatched = (value: any, record: any) => {
  const flatArray = Object.values(flattenObject(record));
  return flatArray?.some((x: any) => {
    return getFormattedValue(x)?.toLowerCase()?.includes(value?.toLowerCase());
  });
};

const getFormattedValue = (value: any) => {
  let formattedValue = value;
  if (typeof value === "number") {
    formattedValue = value.toString();
  } else {
    const date = moment(value);
    formattedValue = date.isValid() ? getDateTimeString(value) : value;
  }
  return formattedValue;
};

export const flattenObject = (obj: any) => {
  const result: any = [];

  const filteredObject = Object.values(obj).filter((x: any) => {
    if (x?.hasOwnProperty("createdAt")) return false;
    return x && !Array.isArray(x);
  });

  filteredObject.forEach((item: any) => {
    if (typeof item === "string") {
      result.push(item);
    } else if (typeof item === "object") {
      Object.values(item).forEach((value) => {
        result.push(value);
      });
    }
  });
  return result;
};

export const sortAlphaNum = (a: string | null, b: string | null) => {
  if (a === null && b === null) return 0;
  if (a === null) return -1;
  if (b === null) return 1;

  return a.localeCompare(b, "en", { numeric: true });
};

export const sortBooleans = (a: boolean, b: boolean) => {
  if (a === b) return 0;
  if (a === false && b === true) return -1;
  return 1;
};

export const orderAlphaNumeric = (data: any, key: string) => {
  return data
    .slice()
    .sort((a: any, b: any) =>
      a[key].localeCompare(b[key], undefined, { numeric: true })
    );
};

export const getHondurasCities = () => {
  let cities;
  cities = citiesHonduras?.reduce((result: any, x: any) => {
    return result.concat(x.municipios);
  }, []);
  cities = uniqBy(cities, "name");
  return orderAlphaNumeric(cities, "name");
};

export const checkIfCodeExists = (codeToCheck: string) => {
  const allCodes = citiesHonduras.flatMap((city) =>
    city.municipios.map((municipio) => municipio.id)
  );
  return allCodes.includes(codeToCheck);
};

export const validatePassportNumber = (passportNumber: string) => {
  const regex = /^[A-Z0-9]{6,20}$/;
  return regex.test(passportNumber);
};
