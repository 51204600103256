import { Form, Input } from "antd";

const { TextArea } = Input;

const NotesDetails: React.FC = () => {
  return (
    <>
      <Form.Item name="notes">
        <TextArea style={{ width: "100%" }} rows={10} maxLength={500} />
      </Form.Item>
    </>
  );
};

export default NotesDetails;
